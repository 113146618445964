<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>



<!-- Wrapper -->
<div class="w-screen min-w-0">

    <!-- Navigation -->
    <fuse-vertical-navigation *ngIf="userLogged && navigation" class="bg-white print:hidden fixed md:top-20 top-14 z-160 mt-0" [appearance]="navigationAppearance"
        [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.default"
        [opened]="!isScreenSmall">
        <!-- Navigation header hook -->
        <!-- <ng-container fuseVerticalNavigationContentHeader> -->
            <!-- Logo -->
            <!-- <div class="flex items-center justify-center h-20">
                <img class="w-8" src="assets/images/logo/logo.svg" alt="Logo image">
            </div> -->
        <!-- </ng-container> -->
    </fuse-vertical-navigation>

    <!-- Header -->
    <div *ngIf="userLogged"
        class="fixed flex flex-0 items-center w-full md:h-20 h-14 md:px-12 px-2 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden top-0">
        <div class="flex items-center">
            <!-- Logo -->
            <!-- <div class="flex items-center justify-center h-20"> -->
                <img class="flex md:w-32 w-24"
                src="../../../assets/images/logo/Logo Empleos Activos para fondos claros 1logo.png" alt="Logo image">
            <!-- </div> -->
            <!-- Navigation toggle button -->
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')" class="md:hidden inline-flex md:ml-24 ml-2">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button class="hidden md:inline-flex md:ml-24 ml-2" mat-icon-button (click)="toggleNavigationAppearance()">
                <!-- <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon> -->
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
        </div>
        <div  *ngIf="showSearch() === true"  class="w-3/4 ml-8">
            <mat-form-field class="w-1/2 fuse-mat-rounded fuse-mat-dense query-postulation">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput placeholder="Título, identificación, empresa, cargo..." [formControl]="search">
            </mat-form-field>
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages> -->
            <notifications></notifications>
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto h-[88%]" [class.ml-20]="!isScreenSmall && userLogged"  [class.md:mt-20]="userLogged" [class.mt-14]="userLogged"  >
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
