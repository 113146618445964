import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'app-session-out',
    templateUrl: './session-out.component.html',
    styles: [],
    standalone: true,
    imports: [MatInputModule, FormsModule, CommonModule, MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule]
})
export class SessionOutComponent {
    constructor(private readonly authService: AuthService) { }

    close(): void {
        this.authService.signOut();
        location.href = '/sign-in';
    }
}
