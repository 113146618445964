import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { PermissionValidator } from '../permissions/permissions-validator.service';
import { AccessControl } from '../permissions/interfaces';

export const curriculumGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);
    const permissionValidator = inject(PermissionValidator);
    const accessControl = inject(AccessControl);
    return authService.verifyPermissions().pipe(map((response) => {
        if (!response.permissions) {
            console.error("user doesn't have permissions ECRR:01");
            authService.signOut();
            router.navigate([permissionValidator.permissions.defaultRoute]);
            return false;
        }

        permissionValidator.setPermissions(response);

        if (!permissionValidator.can(accessControl.createHdv)) {
            console.error("user doesn't have permissions or access is disabled ECRR:02");
            router.navigate([permissionValidator.permissions.defaultRoute]);
            return false;
        }
        return true;
    }), catchError(error => {
        console.error(error);
        if (error.error.reLoggin) {
            authService.signOut();
            router.navigate([permissionValidator.permissions.defaultRoute]);
        }
        return of(false);
    }));
};
