export class Permissions {
    id : string;
    name : string;
    defaultRoute: string;
    permissions : Array<ListPermissions>;
}

export interface ListPermissions {
    path: string;
    name: string;
    status: boolean;
}
