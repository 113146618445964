import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Requisition {
    public instance: RequisitionInstance | null = null;

    setInstance(requisition: string, usuUser: string) {
        if (this.instance === null) {
            this.instance = { requisition, usuUser };
        }
    }

    public getInstance(): RequisitionInstance {
        return this.instance;
    }

    public existInstance(): boolean {
        return this.instance !== null;
    }
}

export interface RequisitionInstance { requisition: string, usuUser: string }

