import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-popup-loading',
  templateUrl: './popup-loading.component.html',
  styleUrls: ['./popup-loading.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule]
})
export class PopupLoadingComponent {
  constructor(private dialogRef: MatDialogRef<PopupLoadingComponent>) {}

  cancelOperation() {
    this.dialogRef.close('cancel');
  }
}
