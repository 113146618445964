import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionOutComponent } from 'app/components/session-out/session-out.component';
import { AuthService } from 'app/core/auth/auth.service';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const router: Router = inject(Router);
    const dialog = inject(MatDialog);
    const snapshotUrl = router.routerState.snapshot.url;

    //get dialog id
    const dialogRef = dialog.getDialogById('sessionOutDialog');

    //let newReq = req.clone();
    let newReq = req.clone({
         setHeaders: {
             'Cache-Control': 'no-cache, no-store, must-revalidate',
             'Pragma': 'no-cache',
             'Expires': '0'
         }
    });
    // Request
    if (authService.accessToken) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
    }

    // Response
    return next(newReq).pipe(
        tap((event: HttpEvent<unknown>) => {

            if (event instanceof HttpResponse) {
                const reLogginHeader = event.headers.get('X-ReLoggin');
                if (reLogginHeader && !snapshotUrl.includes('/sign-in') && !snapshotUrl.includes('/sign-up')) {
                    // authService.signOut();
                    // location.href = '/sign-in';
                    if (!dialogRef) {
                        dialog.open(SessionOutComponent, {
                            disableClose: true,
                            width: '400px',
                            id: 'sessionOutDialog'
                        })
                    }
                }
            }
        }),
        catchError((error: HttpErrorResponse) => {
            const reLogginHeader = error.headers.get('X-ReLoggin');
            if (error.error.message === "Invalid or expired token") {
                if (!dialogRef) {
                    dialog.open(SessionOutComponent, {
                        disableClose: true,
                        width: '400px',
                        id: 'sessionOutDialog'
                    })
                }
            }

            if (reLogginHeader && !snapshotUrl.includes('/sign-in') && !snapshotUrl.includes('/sign-up')) {
                authService.signOut();
                location.href = '/sign-in';
            }

            return throwError(error);
        }),
    );
};
