import { Component, OnInit } from '@angular/core';
import { Requisition } from './services/requisition';
import { RequisitionService } from './services/requisition.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'app-requisicion',
    templateUrl: './requisicion.component.html',
    styles: [],
    standalone: true,
    providers: [RequisitionService]
})
export class RequisicionComponent implements OnInit {

    constructor(private readonly requisition: Requisition, private readonly requisitionService: RequisitionService,
        private readonly router: Router,
        private readonly authService: AuthService
    ) { }


    ngOnInit(): void {
        const requisitonData = this.requisition.getInstance();
        if (!this.requisition.existInstance()) {
            this.router.navigate(['/sign-in']);
        }

        if (this.requisition.existInstance()) {
            this.requisitionService.auth({
                requisitionNumber: requisitonData.requisition,
                usuUser: requisitonData.usuUser
            }).subscribe({
                next: async (response) => {
                    if (response.toLogin) {
                        this.authService.signOut();
                        this.router.navigate(['/sign-in']);
                        return;
                    }
                    const { accessToken, ...userData } = response;
                    this.authService.check().subscribe(isAutenticated => {
                        if (isAutenticated) {
                            this.authService.signOut();
                        }
                    });
                    await this.authService.authRequisition(accessToken, userData);
                    setTimeout(() => { // Retrasa la actualización
                        this.router.navigate(['/crear-oferta']);
                    }, 1000);
                },
                error: (err) => {
                    console.log(err);
                    this.authService.signOut();
                    this.router.navigate(['/sign-in']);
                    return;
                }
            });
        }
    }

}
