import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Requisition } from 'app/modules/auth/requisicion/services/requisition.singleton';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const requisition = inject(Requisition);

    const requisicion = route.queryParamMap.get('requisicion');
    const usuUser = route.queryParamMap.get('usuUsuario');

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) => {
            // If the params exis on the Url
            if (requisicion && usuUser) {
                if (!requisition.existInstance()) {
                    requisition.setInstance(requisicion, usuUser);
                }
                return of(router.parseUrl(`/requisition`));
            }

            // If the user is authenticated...
            if (authenticated && !requisition.existInstance()) {
                return of(router.parseUrl(''));
            }

            // Allow the access
            return of(true);
        }),
    );
};
