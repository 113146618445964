import { Injectable } from "@angular/core";
import { AccessControl, Permissions } from "./interfaces";

@Injectable({
    providedIn: 'root'
})
export class PermissionValidator {
    public permissions: Permissions;
    setPermissions(dataRole: Permissions) {
        this.permissions = dataRole;
    }

    canActivateRoute(access: string): boolean {
        if (!this.permissions.permissions) return false;

        if (this.permissions.permissions.findIndex(item => item.path === access) === -1) {
            return false;
        }
        return this.permissions.permissions.some(permission => permission.path === access && permission.status === true);
    }


    can(access: string): boolean {

        if (!this.permissions.permissions) return false;
        if (this.permissions.permissions.findIndex(item => item.path === access) === -1) {
            return false;
        }
        return this.permissions.permissions.some(permission => permission.path === access && permission.status === true);
    }

}
