import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
// import { Notification } from 'app/layout/common/notifications/notifications.types';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { Notification as Notify } from '../interfaces/notification.interface';
import { PermissionValidator } from 'app/core/auth/permissions/permissions-validator.service';
import { AccessControl } from 'app/core/auth/permissions/interfaces';

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications',
    standalone     : true,
    imports        : [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe],
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: Notify[] = [];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    //usuario autenticado
    userLogged: User;

    //paginación
    pageSize  = 10;
    pageIndex = 0;
    count     = 0;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private notificationService: NotificationsService,
        private authService: AuthService,
        private router: Router,
        public readonly permissionValidator : PermissionValidator,
        public accessControl : AccessControl,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.userLogged = this.authService.user;
        this.loadNotifications();
    }

    //cargar notificaciones
    loadNotifications(): void
    {
        this.notificationService.findAll(this.userLogged.id, this.pageSize, this.pageIndex).subscribe({
            next: (response) => {
                this.notifications = [...this.notifications, ...response.notifications];
                this.count = response.count;
                console.log("notifications", this.notifications);

                this._calculateUnreadCount();

                this._changeDetectorRef.markForCheck();
            },
            error(error){
                console.log("error", error);
            }
        });
    }

    //cargar más notificaciones
    loadMore(): void
    {
        if (this.notifications.length < this.count) {
            this.pageIndex++;
            // this.pageSize++;
            this.loadNotifications();
        }
    }

    //convertir a Date
    convertToDate(timestamp: { _seconds: number; _nanoseconds: number }): Date {
        return new Date(
            timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void
    {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
    */
    toggleRead(notification: Notify): void
    {
        // Llamada al servicio para marcar la notificación como leída
        this._notificationsService.read(notification.id).subscribe({
            next: (updatedNotification) => {
                console.log("leida", updatedNotification);
                // Actualiza el array de notificaciones con la respuesta del backend
                this.notifications = this.notifications.map((notif) =>
                    notif.id === updatedNotification.id ? updatedNotification : notif
                );

                // Recalcula el conteo de notificaciones no leídas
                this._calculateUnreadCount();

                // Actualiza la vista
                this._changeDetectorRef.markForCheck();
            },
            error: (error) => {
                console.error('Error al marcar la notificación como leída:', error);
            }
        });
    }

    /**
     * Delete the given notification
     */
    // delete(notification: Notify): void
    // {
    //     // Delete the notification
    //     this._notificationsService.delete(notification.id).subscribe();
    // }
    delete(notification: Notify): void
    {
        // Delete the notification using the service
        this._notificationsService.delete(notification.id).subscribe({
            next: () => {
                // Remove the notification from the array
                this.notifications = this.notifications.filter(n => n.id !== notification.id);

                // Decrease the count
                this.count--;

                // Recalculate unread count
                this._calculateUnreadCount();

                // Update view
                this._changeDetectorRef.markForCheck();

                // Load more notifications if we removed one and there are more in the backend
                if (this.notifications.length < this.count) {
                    this.loadMore();
                }
            },
            error: (error) => {
                console.error('Error deleting notification:', error);
            }
        });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX : 'start',
                        originY : 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX : 'end',
                        originY : 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX : 'end',
                        originY : 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() =>
        {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter(notification => notification.readAt === null).length;
        }

        this.unreadCount = count;
    }


    redirect(link: string){
        this.permissionValidator.can(this.accessControl.approvePublicationBySelection)
        || this.permissionValidator.can(this.accessControl.approvePublicationByCommunications)
        || this.permissionValidator.can(this.accessControl.rejectPublicationBySelection)
        || this.permissionValidator.can(this.accessControl.approvePublicationByCommunications)
        ? this.router.navigate([`/crear-oferta/${link}`])
        : this.router.navigate([`/crear-oferta/list-of-offers-to-publish`, 'todas']);
    }

}
