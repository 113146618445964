import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { PermissionValidator } from '../permissions/permissions-validator.service';
import { AccessControl } from '../permissions/interfaces';

export const offerGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);
    const permissionValidator = inject(PermissionValidator);
    const accessControl = inject(AccessControl);

    return authService.verifyPermissions().pipe(map((response) => {
        if (!response.permissions) {
            console.error("user doesn't have permissions ECRR:01");
            router.navigate([permissionValidator.permissions.defaultRoute]);
            authService.signOut();
            return false;
        }

        const url = route.url[0] ? route.url[0].path : route.url;
        permissionValidator.setPermissions(response);

        switch (url) {
            case "red-social":
                if (!permissionValidator.can(accessControl.roleRequiresSocialMediaPosting)) {
                    router.parseUrl(permissionValidator.permissions.defaultRoute);
                    router.navigate([permissionValidator.permissions.defaultRoute]);
                    return false;
                }
                break;
            case "select-template":
                if (!permissionValidator.can(accessControl.roleRequiresSocialMediaPosting)) {
                    router.parseUrl(permissionValidator.permissions.defaultRoute);
                    router.navigate([permissionValidator.permissions.defaultRoute]);
                    return false;
                }
                break;
            case "template-creator":
                if (!permissionValidator.can(accessControl.roleRequiresSocialMediaPosting)) {
                    router.parseUrl(permissionValidator.permissions.defaultRoute);
                    router.navigate([permissionValidator.permissions.defaultRoute]);
                    return false;
                }
                break;
            case "list-of-offers-to-publish":
                if (!permissionValidator.can(accessControl.roleRequiresSocialMediaPosting)) {
                    router.parseUrl(permissionValidator.permissions.defaultRoute);
                    router.navigate([permissionValidator.permissions.defaultRoute]);
                    return false;
                }
                break;
            case "seleccion":
                if (!permissionValidator.can(accessControl.createOffer)) {
                    router.parseUrl(permissionValidator.permissions.defaultRoute);
                    router.navigate([permissionValidator.permissions.defaultRoute]);
                    return false;
                }
                break;
            default:
                if (!permissionValidator.can(accessControl.createOffer)) {
                    router.parseUrl(permissionValidator.permissions.defaultRoute);
                    router.navigate([permissionValidator.permissions.defaultRoute]);
                    return false;
                }
                break;
        }

        return true;
    }), catchError(error => {
        console.error(error);
        if (error.error.reLoggin) {
            authService.signOut();
            router.navigate(['/sign-in']);
        }
        return of(false);
    }));
};

const redirect = (router: Router, authService: AuthService) => {
    console.error("user doesn't have permissions or access is disabled ECRR:02");
    authService.signOut();
    router.navigate(['/sign-in']);
    return false;
}
