import { Injectable } from "@angular/core";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class AccessControl {
    public createHdv                         : string = 'createHdv';
    public createOffer                       : string = 'create_offer';
    public createOfferWithCompanyPublicly    : string = 'create_offer_with_company_publicly';
    public roleRequiresSocialMediaPosting    : string = 'role_requires_social_media_posting';
    public createTemplate                    : string = 'create_template';
    public editTemplate                      : string = 'edit_template';
    public editPost                          : string = 'edit_post';
    public createPost                        : string = 'create_post';
    public approvePublicationBySelection     : string = 'approve_publication_by_selection';
    public rejectPublicationBySelection      : string = 'reject_publication_by_selection';
    public approvePublicationByCommunications: string = 'approve_publication_by_communications';
    public rejectPublicationByCommunications : string = 'reject_publication_by_communications';
    public editAllOffers                     : string = 'edit_all_offers';
    public duplicateAllOffers                : string = 'duplicate_all_offers';
    public viewOrEditAppliedStaffOnAllOffers : string = 'view_or_edit_applied_staff_on_all_offers';
    public publishOffers                     : string = 'publish_offers';
}
