import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { User } from '../user/user.types';
import { environment } from 'environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({providedIn: 'root'})
export class AuthService
{
    private _authenticated: boolean = false;

    // private apiUrl = `http://localhost:3000/api/v1`
    private apiUrl = `${environment.apiHost}/${environment.serviceAuth}`
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
    )
    {
        const accessToken = this.accessToken;
        const user = this.user;
        if(accessToken && user){
            this._authenticated = true
            this._userService.user = user;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    set user(value: Object)
    {
        localStorage.setItem('user', JSON.stringify(value));
    }

    get user(): User
    {
        return JSON.parse(localStorage.getItem('user')) ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        const encryptedCredentials = CryptoJS.AES.encrypt(JSON.stringify(credentials), environment.secret_encrypt).toString();
        return this._httpClient.post(`${this.apiUrl}/auth/login`, { data: encryptedCredentials }).pipe(
            catchError((error) => {
                console.error('Error en el inicio de sesión servicio:', error);

                let errorMessage = 'Error en el inicio de sesión';

                if (error && error.error && error.error.message) {
                    errorMessage = error.error.message;
                }

                return throwError({ error: error });
            }),
            switchMap((response: any) => {
                if (response.error) {
                    console.error('Error en el inicio de sesión servicio response:', response.error);
                    return throwError({ error: true, message: response.error });
                } else {
                    // Almacenar el token de acceso en el almacenamiento local
                    this.accessToken = response.accessToken;

                    // Establecer la bandera de autenticación en true
                    this._authenticated = true;

                    // Almacenar la información del usuario en el servicio _userService
                    this._userService.user = response;
                    const {accessToken, ...user} = response;
                    this.user = user;

                    // Retornar un nuevo observable con la respuesta
                    return of(response);
                }
            })
        );
    }



    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError((error) =>{
                throw error;
                // Return false
                //of(false),
            }),
            switchMap((response: any) =>
            {
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user, isMigrateUser): Observable<any>
    {
        const encryptedCredentials = CryptoJS.AES.encrypt(JSON.stringify(user), environment.secret_encrypt).toString();
        if(isMigrateUser) {
            return this._httpClient.post(`${this.apiUrl}/auth/register-migrate`, {data :encryptedCredentials});
        }
        return this._httpClient.post(`${this.apiUrl}/auth/register`, {data :encryptedCredentials});
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    authRequisition(token : string,user : any){
        return new Promise((resolve, reject) => {
            this._authenticated = true;
            this._userService.user = user;
            this.user = user; //Info user
            this.accessToken = token;
            resolve(true);
        })
    }

    verifyAccount(token): Observable<string>{
        const url = this.apiUrl + '/auth/verify';
        const params =new HttpParams().set('token',token);

        return this._httpClient.get<string>( url, { params } ).pipe(
            catchError(error => {
              // Puedes manejar el error aquí o lanzarlo nuevamente
              console.error('Error al verificar la cuenta:', error);
              return throwError(error);
            })
          );
    }

    verifyPermissions(): Observable<any> {
        const url = this.apiUrl + '/auth/check-permissions';
        return this._httpClient.post(url,{token : this.accessToken});
    }

    loadPDFTermsToS(): Observable<ArrayBuffer> {
        const _apiUrl = this.apiUrl + '/auth/pdf/ToS';
        const headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });

        return this._httpClient
            .get(_apiUrl, { responseType: 'arraybuffer', headers: headers })
            .pipe(catchError(this.handleError));
    }

    loadPDFTermsPP(): Observable<ArrayBuffer> {
        const _apiUrl = this.apiUrl + '/auth/pdf/PP';
        const headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });

        return this._httpClient
            .get(_apiUrl, { responseType: 'arraybuffer', headers: headers })
            .pipe(catchError(this.handleError));
    }

    checkEmailAvailability(email: string): Observable<any> {
        const _apiUrl = this.apiUrl + '/auth/check-email';
        return this._httpClient.post<any>(_apiUrl, { email });
    }

    checkIdNumberAvailability(id: string, documentType: string): Observable<any> {
        const _apiUrl = this.apiUrl + '/auth/check-id';
        return this._httpClient.post<any>(_apiUrl, { id, documentType });
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        console.error('Error al cargar el archivo PDF:', error);
        return throwError('Error al cargar el archivo PDF');
    }
}
