/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // },
    {
        id   : 'home',
        title: 'Home',
        type : 'basic',
        isForCandidate: true,
        icon : 'heroicons_outline:home',
        link : '/registro_datos'
    },
    {
        id   : 'create-offer',
        title: 'Creación de ofertas',
        type : 'basic',
        isForCandidate: false,
        icon : 'assignment_turned_in',
        link : '/crear-oferta'
    },
    {
        id   : 'offer-list',
        title: 'Listado de ofertas',
        type : 'basic',
        isForCandidate: false,
        icon : 'assignment',
        link : '/ofertas/seleccion'
    },
    {
        id   : 'empleos',
        title: 'Empleos para ti',
        type : 'basic',
        isForCandidate: true,
        icon : 'heroicons_solid:briefcase',
        link : '/ofertas/publicas'
    },
    {
        id   : 'postulaciones',
        title: 'Tus postulaciones',
        type : 'basic',
        isForCandidate: true,
        icon : 'assignment_turned_in',
        link : '/ofertas/postulaciones'
    },
    {
        id   : 'buscador',
        title: 'Buscar candidatos',
        type : 'basic',
        isForCandidate: false,
        icon : 'heroicons_solid:magnifying-glass',
        link : '/buscador'
    },
    {
        id   : 'social-media',
        title: 'Publicación en redes sociales',
        type : 'basic',
        isForCandidate: false,
        icon : 'heroicons_solid:share',
        link : '/crear-oferta/list-of-offers-to-publish/publications'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
