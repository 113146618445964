import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@
    Injectable()
export class RequisitionService {

    constructor(private readonly _httpService: HttpClient) { }
    //apiUrl = `http://localhost:3000/api/v1/auth`;
    private apiUrl = `${environment.apiHost}/${environment.serviceAuth}/auth`

    auth(data: RequisitionData): Observable<any> {
        return this._httpService.post(`${this.apiUrl}/requistion-login`, data);
    }

    getRequisitionData(requisition: string): Observable<any> {
        return this._httpService.post(`${this.apiUrl}/requisiton-info`, { requisition });
    }
    getRequisitionDataByCode(requisitionCode: number): Observable<any> {
        return this._httpService.post(`${this.apiUrl}/requisiton-info`, { requisitionCode });
    }

}

export interface RequisitionData {
    requisitionNumber: string;
    usuUser: string;
}

